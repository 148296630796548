// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-information-js": () => import("./../../../src/pages/area-information.js" /* webpackChunkName: "component---src-pages-area-information-js" */),
  "component---src-pages-expansive-retreat-js": () => import("./../../../src/pages/expansive-retreat.js" /* webpackChunkName: "component---src-pages-expansive-retreat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-relaxation-cabin-js": () => import("./../../../src/pages/relaxation-cabin.js" /* webpackChunkName: "component---src-pages-relaxation-cabin-js" */),
  "component---src-pages-rustic-cabin-js": () => import("./../../../src/pages/rustic-cabin.js" /* webpackChunkName: "component---src-pages-rustic-cabin-js" */),
  "component---src-pages-sent-js": () => import("./../../../src/pages/sent.js" /* webpackChunkName: "component---src-pages-sent-js" */),
  "component---src-pages-serenity-cabin-js": () => import("./../../../src/pages/serenity-cabin.js" /* webpackChunkName: "component---src-pages-serenity-cabin-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

